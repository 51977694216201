@import 'src/scss/functions';
@import 'src/scss/variables';
@import 'src/scss/palette';

.review-card-seo {
    padding: snif-grid(2);
    margin-bottom: snif-grid(2);
    border: 1px solid $soft-gray;
    border-radius: 12px;
    background-color: $white;

    @media #{$max-width-mobile} {
        margin-bottom: 0;
    }

    &__avatar {
        width: 48px;
        height: 48px;
        margin-right: snif-grid(2);
        object-fit: cover;
        border: none;
    }
}

.review-card {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 24px 16px;
    margin-bottom: 32px;

    width: 300px;
    max-width: 100%;
    overflow: hidden;

    &__avatar {
        width: 56px;
        height: 56px;
        object-fit: contain;
        margin-right: 16px;
    }

    &__m-btm {
        margin-bottom: 15px;
    }

    &__m-btm-2 {
        margin-bottom: 10px;
    }
}

.review-card-seo,
.review-card {
    .review-text {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;

        &.text-expanded {
            display: block;
            -webkit-line-clamp: unset;
            -webkit-box-orient: unset;
            text-overflow: unset;
        }
    }

    .review-expand-btn {
        float: right;
        margin: snif-grid(1) 0 snif-grid(2);
    }
}
