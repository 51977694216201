@import 'src/scss/functions';
@import 'src/scss/palette';

.blog-categories-box {
    &__title {
        display: block;
        margin: snif-grid(2) 0;
        font-weight: 500;
        text-decoration: underline !important;
        text-decoration-color: transparent !important;
        text-underline-offset: 8px !important;
        transition: all linear 200ms;
        cursor: pointer;

        h3 {
            font-size: 18px;
            line-height: 24px;
        }

        @media #{$min-width-mobile} {
            margin: snif-grid(4) 0;
            font-weight: 600;

            h3 {
                font-size: 32px;
                line-height: 40px;
            }
        }

        &:hover {
            color: $green !important;
            text-decoration: underline !important;
            text-decoration-color: $green !important;
        }
    }

    &__posts-box {
        list-style: none;
        padding-inline-start: 0;

        @media #{$min-width-mobile} {
            display: flex;
            gap: 24px;
        }
    }

    &__post {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: snif-grid(3);

        picture,
        img {
            width: 96px;
            height: 72px;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
        }

        h4 {
            width: calc(100% - 112px);
        }

        @media #{$min-width-mobile} {
            display: block;
            width: 20%;

            h4 {
                width: 100%;
                margin-bottom: 0;
            }

            picture,
            img {
                width: 100%;
                height: 160px;
            }
        }
    }

    &__desc {
        display: none;

        @media #{$min-width-mobile} {
            display: block;
            font-family: 'Lora', serif;
            font-size: 16px;
            line-height: 28px;
            color: $dark-gray;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
