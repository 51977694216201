@import 'src/scss/functions';
@import 'src/scss/palette';

.home {
    &__sticky {
        position: fixed;
        width: 100vw;
        top: 56px;
        display: none;
        padding: snif-grid(2);
        background-color: $green-bg-host;
        animation: fade 200ms;
        opacity: 0;
        z-index: 25;

        .desktop-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 1300px !important;
        }

        p:nth-child(1) {
            display: flex;
            align-items: center;
            width: 184px;
        }

        a:nth-child(2) {
            width: calc(100% - 184px);
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &.show {
            display: block;
            opacity: 1;

            @media #{$max-width-mobile} {
                &.has-app-banner {
                    top: 141px;
                }
            }
        }

        @media #{$min-width-mobile} {
            top: 80px;
            padding: snif-grid(3) snif-grid(2);

            &.show {
                .desktop-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p:nth-child(1) {
                        width: calc(100% - 200px);
                    }

                    a:nth-child(2) {
                        width: 200px;
                    }
                }
            }
        }
    }
}
