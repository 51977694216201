@import 'src/scss/variables';
@import 'src/scss/palette';
$logo-size: 50px;

.circle-image {
    display: block;
    width: $logo-size;
    height: $logo-size;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid $soft-gray;
}
