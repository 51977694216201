@import 'src/scss/palette';
@import 'src/scss/functions';

.rec-experts {
    &__our-experts {
        display: flex;
        margin-bottom: snif-grid(6);

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        > div:nth-child(1) {
            width: 80px;

            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
            }
        }

        > div:nth-child(2) {
            width: calc(100% - 100px);
            padding-left: snif-grid(5);
        }

        @media #{$max-width-mobile} {
            display: block;

            > div:nth-child(1) {
                width: 120px;
                margin-bottom: snif-grid(3);

                img {
                    width: 120px;
                    height: 120px;
                }
            }

            > div:nth-child(2) {
                width: 100%;
                padding-left: 0;
            }
        }
    }
}
