@import 'src/scss/palette';
@import 'src/scss/functions';

.sniff-as-seen {
    padding-bottom: 18px;

    &__img {
        filter: grayscale(1);
    }

    &__m-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
    }

    &__slider {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 172px;
    }

    &__slider-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% / 3 - 16px);
        margin-right: 24px;

        img {
            max-height: 44px;
        }

        &:nth-last-child(1) {
            margin-right: 0;
        }
    }

    &__seen-ssr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: snif-grid(2);

        > div {
            width: calc(100% / 6 - 24px);
            margin-bottom: snif-grid(1);

            @media #{$max-width-mobile} {
                width: calc(100% / 3 - 12px);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }

    .splide__pagination {
        bottom: -22px;

        li {
            margin-right: 8px;

            @media #{$max-width-mobile} {
                margin-right: 6px;
            }

            &:nth-last-child(1) {
                margin-right: 0;
            }
        }

        &__page {
            width: 12px;
            height: 12px;
            margin: 0 0 4px;
            transition: all 300ms;

            &.is-active {
                width: 12px;
                height: 12px;
                background-color: $green;
                transform: scale(1.15);
                opacity: 1;
            }

            @media #{$max-width-mobile} {
                width: 8px;
                height: 8px;

                &.is-active {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}
