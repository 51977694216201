.big-picture {
    border-radius: 0 !important;

    img {
        object-fit: cover;
        object-position: center;
    }

    .left {
        left: 50%;
    }

    .right {
        width: 50%;
    }

    .bottom {
        top: auto;
    }
}
